import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { environment } from '../../../environments/environment';
import moment from 'moment';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class EducationService {

  constructor(private http: HttpBaseService) { }

  getEducations() {
    return this.http.get('my/educations');
  }

  getEducation(id: string) {
    return this.http.get(`my/educations/${id}`);
  }

  createEducation(education: any) {

    education.school.startDate = moment(education.school.startDate, 'dd/MMM/yyyy');
    education.school.endDate = moment(education.school.endDate, 'dd/MMM/yyyy');

    return this.http.post('my/educations', education);
  }

  updateEducation(education: any) {

    education.school.startDate = moment(education.school.startDate, 'dd/MMM/yyyy');
    education.school.endDate = moment(education.school.endDate, 'dd/MMM/yyyy');

    return this.http.put('my/educations/' + education._id, education);
  }

  deleteEducation(id: string) {
    return this.http.delete('my/educations/' + id);
  }

  getDegrees() {
    const params = new HttpParams().set('noCache', new Date().getTime());
    return this.http.get('degrees', {params});
  }

  searchSchool(query: string) {
    return this.http.get(`schools/search/${query}`);
  }
}
